<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
              >組織資料設定</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'OrganizationVariableList',
              }"
              >組織參數管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>查看</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-form>
      <b-overlay :show="showLoading">
        <b-card :title="`${organizationName}新增組織參數`">
          <b-card-body>
            <organization-variable-form
              :readonly="true"
              :validationErrors="validationErrors"
              :organizationVariable="organizationVariable">
            </organization-variable-form>

            <b-button class="mr-3" @click="goListPage" variant="outline-danger">返回</b-button>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-form>
  </div>
</template>


<script>
import {mapState} from 'vuex'
import OrganizationVariableForm from './OrganizationVariableForm'
import organizationVariableApi from "@/apis/organization-variable";

export default {
  components: { OrganizationVariableForm },
  data () {
    return {
      showLoading: true,
      validationErrors: null,
      organizationVariable: {
        key: null,
        value: null,
        type: null,
      },
      organizationName: this.$store.state.general.organization.name,
    }
  },
  computed: {
    ...mapState('general', {
      organization: state => state.organization,
    }),
  },
  mounted() {
    this.fetchOrganizationVariable();
  },
  methods: {
    async fetchOrganizationVariable() {
      const response = await organizationVariableApi.get(
        {
          org_code: this.organization.id,
          organization_variable_id: this.$route.params.organization_variable_id,
        }
      )
      this.organizationVariable = response.data.data
      this.showLoading = false
    },
    goListPage() {
      if (this.$route.params.merchant_id) {
        this.$router.push({
          name: 'OrganizationVariableListByMerchant',
          params: { merchant_id: this.$route.params.merchant_id }
        })
        return;
      }

      this.$router.push({name: 'OrganizationVariableList'})
    },
  }
}
</script>
