<template>
  <div>
    <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
      <validation-error-alert v-if="validationErrors" :errors="validationErrors"></validation-error-alert>
      <b-form-group label="Key">
        <b-form-input type="text" v-model="organizationVariable.key" required :disabled="readonly || disabledKey"></b-form-input>
      </b-form-group>
      <b-form-group label="名稱">
        <b-form-input type="text" v-model="configMapping.name" required :disabled="true"></b-form-input>
      </b-form-group>
      <b-form-group label="Type">
        <b-form-select v-model="organizationVariable.type" required :disabled="readonly">
          <b-form-select-option :value="null">請選擇</b-form-select-option>
          <b-form-select-option value="string">string</b-form-select-option>
          <b-form-select-option value="json">json</b-form-select-option>
          <b-form-select-option value="boolean">boolean</b-form-select-option>
          <b-form-select-option value="number">number</b-form-select-option>
          <b-form-select-option value="file">file</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Value">
        <b-form-checkbox
          v-if="configMapping.type == 'boolean'"
          v-model="organizationVariable.value"
          :disabled="readonly"
        ></b-form-checkbox>
        <editor
          v-else-if="configMapping.type == 'json' || configMapping.type == 'array'"
          v-model="editValue"
          :disabled="readonly"
          width="100%"
          height="200px"
          @init="editorInit"
          lang="json"
          theme="chrome"
        >
        </editor>
        <b-form-input
          v-else-if="configMapping.type == 'number'"
          v-model="organizationVariable.value"
          :disabled="readonly"
          type="number"
        ></b-form-input>
        <button
          v-else-if="configMapping.type == 'file'"
          class="btn btn-link px-2 text-dark align-self-start upload-button"
        >
          <b-form-file
            @change="upload($event)"
          />
          <a :href="organizationVariable.value" target="_blank"><small> 目前路徑：{{organizationVariable.value || '未上傳'}} </small></a>
        </button>
        <b-form-input
          v-else
          v-model="organizationVariable.value"
          :disabled="readonly"
          type="text"
        ></b-form-input>
      </b-form-group>
    </b-overlay>
  </div>
</template>


<script>
import editor from 'vue2-ace-editor'
import fileMixin from "@/mixins/uploadFile";

export default {
  mixins: [fileMixin],
  components: {editor},
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabledKey: {
      type: Boolean,
      default: false,
    },
    validationErrors: null,
    organizationVariable: {
      key: null,
      value: null,
      type: null,
    },
  },
  data() {
    return {
      editValue: '',
      isLoading: false
    }
  },
  computed: {
    configMapping() {
      return this.organizationVariable.config_mapping || {};
    }
  },
  // editor 套件用 v-model 傳遞時，給 null 會有 bug，所以要用比較繞的方式去綁定 orgVariable.value 跟 editValue
  watch: {
    'organizationVariable.value' () {
      if (this.organizationVariable.value) {
        this.editValue = this.organizationVariable.value
      }
    },
    editValue () {
      this.organizationVariable.value = this.editValue
    },
  },
  methods: {
    editorInit () {
      require('brace/ext/language_tools') //language extension prerequsite...
      require('brace/mode/json')
      require('brace/theme/chrome')
    },
    async upload(e) {
      this.isLoading = true
      let url =  await this.uploadFile(e.target.files[0])
      this.organizationVariable.value = url
      this.isLoading = false
      return url
    },
  },
}
</script>
